<template>
  <div
    v-if="!appmode"
    class="header__wrapper"
    :class="{
      'with-bg-color': showHeaderBackgroundColor
    }"
  >
    <header class="header__container">
      <div
        class="header__accountbar"
        :class="{ 'header__accountbar-placeholder': !showAccountBar }"
      >
        <AccountBar v-if="showAccountBar" />
      </div>
      <div
        class="header__content"
        :class="{
          selfcare: selfcare,
          'background-without-navigation': !showNavigation,
          'with-caption': withCaption
        }"
      >
        <div
          v-if="$breakpoint.fromDesktop() || !showNavigation"
          class="logo-wrapper"
        >
          <a
            class="logo"
            :href="$brand.isFonic() ? 'https://www.fonic.de' : '/'"
            tabindex="1"
          >
            <Icon
              v-if="$breakpoint.fromDesktop()"
              name="logo"
              :height="$brand.isFonic() ? '130' : '79'"
              :width="$brand.isFonic() ? undefined : '137'"
            />
            <Icon
              v-else
              name="logo"
              :height="$brand.isFonic() ? '52' : '52'"
              :width="$brand.isFonic() ? undefined : '90'"
            />
            <span class="visually-hidden">{{
              `${$brand.name} - Startseite`
            }}</span>
          </a>
        </div>
        <div v-if="withCaption" class="header__caption">
          <div>Das ist die Wahrheit</div>
        </div>
        <div
          v-if="!withCaption && showNavigation"
          class="header__navigation"
          :class="{ selfcare: selfcare }"
        >
          <Navigation :selfcare="selfcare" :portal="portal" />
        </div>
      </div>
    </header>
    <div
      class="header__background"
      :class="{
        'with-image': showHeaderBackgroundImage,
        'hide-header': !showNavigation
      }"
    >
      <StageComponent />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AccountBar from 'src/components/common/account_bar/account_bar.vue'
import Icon from '../icon/icon.vue'
import Navigation from 'src/components/common/navigation/navigation.vue'
import StageComponent from 'src/components/common/stages/stages.vue'

export default {
  name: 'HeaderComponent',
  components: {
    Navigation,
    StageComponent,
    AccountBar,
    Icon
  },
  props: {
    hideBackground: {
      type: Boolean,
      default: false
    },
    withCaption: {
      type: Boolean,
      default: false
    },
    selfcare: {
      type: Boolean,
      default: false
    },
    portal: {
      type: Boolean,
      default: false
    },
    showAccountBar: {
      type: Boolean,
      default: true
    },
    showNavigation: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showStagePlaceholder: false
    }
  },
  computed: {
    ...mapState({
      appmode: state => (state.authentication.appmode ? true : false)
    }),
    showHeaderBackgroundImage() {
      return (
        this.$brand.isFonic() &&
        !this.showStagePlaceholder &&
        !this.hideBackground
      )
    },
    showHeaderBackgroundColor() {
      return !this.hideBackground
    }
  },
  created() {
    this.$bus.on('show-stage', this.showStage)
    this.$bus.on('page-change', this.hideStage)
    this.$bus.on('hide-stage', this.hideStage)
  },
  beforeUnmount() {
    this.$bus.off('show-stage', this.showStage)
    this.$bus.off('page-change', this.hideStage)
    this.$bus.off('hide-stage', this.hideStage)
  },
  methods: {
    showStage() {
      this.showStagePlaceholder = true
    },
    hideStage() {
      this.showStagePlaceholder = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header__wrapper {
  height: 100%;

  &.with-bg-color {
    background: var(--root-background);
    transition: background 0.2s linear;
  }
}

.header__background {
  min-height: 45px;
  width: 100%;

  @include breakpoint($up-to-desktop) {
    &.hide-header {
      @include fonic-mobile {
        min-height: 138px;
      }
    }
  }

  @include breakpoint($from-desktop) {
    min-height: 190px;
  }

  &.with-image {
    @include breakpoint($from-desktop) {
      background-image: url('/assets/webp_images/background_header.webp');
      background-position: center;
      min-height: 208px;
    }
  }
}

.header__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.header__content {
  @include full-width;

  display: flex;
  justify-content: space-between;
  gap: 25px;
  background-color: white;
  padding-right: 1rem;
  padding-left: 1rem;
  overflow: visible;

  @include breakpoint($up-to-desktop) {
    padding-right: 3rem;
    box-shadow: 0 5px 5px 0 rgb(0 0 0 / 6%), 0 3px 1px -2px rgb(0 0 0 / 12%),
      0 1px 5px 0 rgb(0 0 0 / 20%);

    @include fonic-mobile {
      border-bottom: 2px solid $c_tropic-blue;
    }

    &.background-without-navigation {
      background: transparent;
      box-shadow: none;
      padding-right: 0;
      width: inherit;
      justify-content: center;
      border-bottom: none;
    }
  }

  @include breakpoint($from-desktop) {
    height: auto;
    background: transparent;
    margin-bottom: 2.5rem;

    &.selfcare {
      margin-top: 0;
    }

    &.with-caption {
      justify-content: flex-start;
      margin-top: 1.5rem;
    }
  }
}

.header__accountbar {
  @include full-width;

  padding: 0 1rem;
  z-index: 10;
}

.header__accountbar-placeholder {
  margin-top: 8px;

  @include breakpoint($from-desktop) {
    margin-top: 30px;
  }
}

.header__navigation {
  width: 100%;
  max-width: 100%;
  margin-top: 36px;
  align-self: flex-start;

  &.selfcare {
    margin-top: 0;
    align-self: center;
  }
}

.logo-wrapper {
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.logo {
  @include fonic {
    margin-left: -6px;

    &:focus {
      border-radius: 50%;
    }
  }

  @include fonic-mobile {
    margin-left: -1rem;
  }

  @include breakpoint($from-desktop) {
    margin-left: 0;

    @include fonic-mobile {
      margin-top: 23px;
    }
  }
}

.header__caption {
  display: none;

  @include breakpoint($from-desktop) {
    @include font-normal;

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    font-size: 2.1rem;
    color: #613815;
  }
}
</style>
